//------------------------------------------------------//
// On browser 📜 scroll full window height  
//------------------------------------------------------//
const body = document.querySelector('body');
window.onscroll = function (ev) {
  if ((window.innerHeight + window.scrollY) >= body.offsetHeight) {
    // you're at the bottom of the page
    body.classList.add('showScrolled');
  } else {
    body.classList.remove('showScrolled');
  }
};
// END On browser 📜 scroll full window height  -------------------------------------//

