(function ($) {
  // jQuery code here...

  $(window).scroll(function () {
    var scroll = $(window).scrollTop();

    if (scroll >= 600) {
      //clearHeader, not clearheader - caps H
      $("#stickyCTA").addClass("visible");
    } else {
      $("#stickyCTA").removeClass("visible");
    }
  });

})(jQuery); // Fully reference jQuery after this point.
