import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { DrawSVGPlugin } from "gsap/DrawSVGPlugin";
window.gsap = gsap;
//------------------------------------------------------//
// Setup 🧦 GSAP and register 📜 ScrollTrigger
// Register more plugins like so: gsap.registerPlugin(ScrollTrigger, splitText);
// Go to https://greensock.com/docs/v3/Installation?checked=core,scrollTrigger,splitText#installer
//------------------------------------------------------//
gsap.registerPlugin(ScrollTrigger, DrawSVGPlugin);

ScrollTrigger.defaults({
  toggleActions: "restart complete resume pause",
  markers: (location.hostname === "localhost" || location.hostname === "127.0.0.1") ? true : false,
});

// END Setup 🧦 GSAP -------------------------------------//

gsap.from("#navigation-slide-toggle", {
  x: 100,
});
//------------------------------------------------------//
// Slide menu 🎛️ toggle
//------------------------------------------------------//
const navigationSlideToggle = document.querySelector('#navigation-slide-toggle');
const navigationSlide = document.querySelector('#navigation-slide');
const body = document.querySelector('body');
// Animation

const timelineMenu = gsap.timeline();
timelineMenu.to(navigationSlide, {
  duration: 0.3,
  y: 0,
});
timelineMenu.to(navigationSlideToggle.querySelector('.icon'), {
  duration: 0.2,
  rotate: -90
});
timelineMenu.from(navigationSlide.querySelectorAll('.wrapper section'), {
  x: -100,
  opacity: 0,
  stagger: {
    amount: 0.2
  }
});
timelineMenu.from(navigationSlide.querySelector('.alloy-person'), {
  duration: 0.2,
  y: 50,
  opacity: 0,
});
timelineMenu.from(navigationSlide.querySelector('.alloy-search'), {
  duration: 0.1,
  opacity: 0,
}, '-=0.1');
// * Dit is voor animateMenu() functie. De eerste keer is het TRUE
timelineMenu.reversed(true);

function animateMenu() { //* Slim toggle animatie. Speel of reverse (3x snelheid) animatie
  if (timelineMenu.reversed()) {
    timelineMenu.reversed(!timelineMenu.reversed()).timeScale(1);
    // * er is een bug in safari waar als je het menu open hebt het text effect er door heen komt. Dit pauseerd en hide hem
    gsap.set(document.querySelector("#alloy-fancy-header .text-effect"), { opacity: 0 })
    timelineHeaderText.pause();
  } else {
    timelineMenu.timeScale(3).reversed(!timelineMenu.reversed());
    // * Zet text effect weer aan
    // ! bug nog steeds zichbaar
    timelineHeaderText.play();
    gsap.set(document.querySelector("#alloy-fancy-header .text-effect"), { opacity: 1 })

  }
}
// On 🐭 click add class to body and toggle 🦻 ARIA lables
navigationSlideToggle.addEventListener('click', () => {
  body.classList.toggle('showNavigationSlide'); // Add class to the body zodat je alles kunt stijlen opbasis van of het menu open is
  navigationSlideToggle.setAttribute('aria-expanded', navigationSlideToggle.getAttribute('aria-expanded') == 'true' ? 'false' : 'true');
  animateMenu(); // Speel animatie
});

// END Slide menu 🎛️ toggle  -------------------------------------//

//------------------------------------------------------//
// Animation viz our approach 
//------------------------------------------------------//
document.querySelectorAll(".viz-approach").forEach(function (container) {
  const timeline = gsap.timeline({
    scrollTrigger: {
      trigger: container, // What element triggers the scroll
      scrub: false, // Add a small delay of scrolling and animation. `true` is direct
      start: "top bottom-=40%", // Can be top, center, bottom 
      end: "100% center", // Can be top, center, bottom 
      // pin: true,
    }
  });
  timeline.from(container.querySelector('#laptop-screen'), {
    // duration: 0.3,
    scaleY: 0,
    transformOrigin: "bottom center"
  });
  timeline.set(container.querySelector('#purple'), { autoAlpha: 1 })
  timeline.set(container.querySelector('#graph'), { autoAlpha: 1 })
  timeline.from(container.querySelector('#purple'), {
    // duration: 0.3,
    x: -200,
  });

  timeline.from(container.querySelector('#graph-purple'), {
    // duration: 0.3,
    drawSVG: 0,
  }, '<');
  timeline.from(container.querySelector('#green-text'), {
    duration: 0.3,
    y: -10,
    opacity: 0
  });

  timeline.set(container.querySelector('#blue'), { autoAlpha: 1 })
  timeline.from(container.querySelector('#blue'), {
    // duration: 0.3,
    y: 100,
  });

  timeline.from(container.querySelector('#graph-blue'), {
    // duration: 0.3,
    drawSVG: 0,
  }, '<');
  timeline.from(container.querySelector('#blue-text'), {
    duration: 0.3,
    y: -10,
    opacity: 0
  });
  timeline.set(container.querySelector('#green'), { autoAlpha: 1 })
  timeline.from(container.querySelector('#green'), {
    // duration: 0.3,
    x: 200,
  });
  timeline.from(container.querySelector('#graph-green'), {
    // duration: 0.3,
    drawSVG: 0,
  }, '<');
  timeline.from(container.querySelector('#purple-text'), {
    duration: 0.3,
    y: -10,
    opacity: 0
  });

});
// END Animation ELEMENT -------------------------------------//
//------------------------------------------------------//
// Animation viz workplace 
//------------------------------------------------------//
document.querySelectorAll(".viz-workplace").forEach(function (container) {
  const timeline = gsap.timeline({
    defaults: { transformOrigin: "center" },
    scrollTrigger: {
      trigger: container, // What element triggers the scroll
      scrub: false, // Add a small delay of scrolling and animation. `true` is direct
      start: "top bottom-=40%", // Can be top, center, bottom 
      end: "100% center", // Can be top, center, bottom 
      // pin: true,
    }
  });
  // Let staggered elements come in from there respected angles
  // With this code each stagger gets their starting positon from the index of the array
  // so the third element gets the postion X: -100 Y: -100, which means from the top left
  let moveX = gsap.utils.wrap([100, 0, -100]);
  let moveY = gsap.utils.wrap([-100, 100, -100]);

  // This timeline is only responsable for roating the outer ring text
  const rotationTl = gsap.timeline({
    repeat: -1,
    defaults: { transformOrigin: "center" }
  });
  rotationTl.to(container.querySelector("#wr-bg"), {
    ease: "none", // Disable easing for a smooth transiton into infinity
    duration: 2,
    rotation: 360
  });

  // const timeline = gsap.timeline({
  //   defaults: { transformOrigin: "center" }
  // });
  timeline.set(container, { autoAlpha: 1 }); // Fixes first flash before animation, see CSS visabilty
  timeline.from(container.querySelector("#workplace-ring"), {
    duration: 1,
    ease: "elastic.out(1, 0.3)",
    scale: 0
  });
  timeline.from(container.querySelectorAll("#workplace-graph > *"), {
    ease: "power4.out",
    y: moveY,
    x: moveX,
    opacity: 0,
    stagger: 0.2
  });
  timeline.add(() => { }, "+=0.5"); // Add a pause delay between elements
  timeline.set(container.querySelector("#wr-text"), { autoAlpha: 1 });
  timeline.from(container.querySelectorAll("#wr-text text"), {
    duration: 1,
    scale: 0.8,
    ease: "elastic.out(1, 0.3)",
    opacity: 0,
    stagger: 0.2
  });
  timeline.add(() => { }, "+=0.5"); // Add a pause delay between elements
  timeline.set(container.querySelector("#workplace-outer"), {
    autoAlpha: 1
  });
  timeline.from(container.querySelectorAll("#workplace-outer > *"), {
    ease: "power4.out",
    y: moveY,
    x: moveX,
    opacity: 0,
    stagger: 0.2
  });
  timeline.add(rotationTl);


});
// END Animation ELEMENT -------------------------------------//
//------------------------------------------------------//
// Header text animation 
//------------------------------------------------------//
const headerText = document.querySelector('#alloy-fancy-header');
const timelineHeaderText = gsap.timeline({ repeat: -1 });

if (headerText) {
  const textItems = headerText.querySelectorAll(".text-effect .list span");
  const duration = 0.5;
  const pause = 2.5;
  const stagger = duration + pause;
  const repeatDelay = (stagger * (textItems.length - 1)) + pause;
  timelineHeaderText.set(headerText.querySelector(".text-effect .list"), { autoAlpha: 1 })
  timelineHeaderText.set(textItems, { transformOrigin: "50% 50% -50" })

  timelineHeaderText.from(textItems, {
    duration: duration,
    rotationX: -90,
    opacity: 0,
    stagger: {
      each: stagger,
      repeat: -1,
      repeatDelay: repeatDelay
    }
  });
  timelineHeaderText.to(textItems, {
    duration: duration,
    rotationX: 90,
    opacity: 0,
    stagger: {
      each: stagger,
      repeat: -1,
      repeatDelay: repeatDelay
    }
  }, stagger);
}
// END Header text animation -------------------------------------//
//------------------------------------------------------//
// Header features animation 
//------------------------------------------------------//
const headerFeatures = document.querySelector('#alloy-fancy-header .features');
const timelineHeaderFeatures = gsap.timeline();

if (headerFeatures) {
  const headerFeaturedItems = headerFeatures.querySelectorAll(".item");
  gsap.set(headerFeaturedItems, { autoAlpha: 1 });
  gsap.set(headerFeaturedItems, { transformOrigin: "50% 50% -50" });

  timelineHeaderFeatures.from(headerFeaturedItems, {
    rotationX: -90,
    opacity: 0,
    stagger: {
      each: 0.3,
      ease: "power2.out"
    }
  });
}
// END Header features animation -------------------------------------//
//--------------------------------//
// Card animation
//--------------------------------//
function cardAnimation(container) {
  const tl = gsap.timeline({
    scrollTrigger: {
      trigger: container, // What element triggers the scroll
      scrub: true, // Add a small delay of scrolling and animation. `true` is direct
      start: "top bottom-=15%", // Can be top, center, bottom 
      end: "60% center", // Can be top, center, bottom 
    }
  })
  tl.from(container.querySelectorAll('.item'), {
    // duration: 0.2,
    y: 100,
    opacity: 0,
    stagger: {
      each: 0.15,
      ease: "Power2.in"
    }
  })
}
// ? Below all these elements have the same animation based on hte function above
document.querySelectorAll('.person-container').forEach(container => {
  cardAnimation(container)
})
document.querySelectorAll('.ACF-include_single_item').forEach(container => {
  cardAnimation(container)
})
document.querySelectorAll('.ACF-include_content_items').forEach(container => {
  cardAnimation(container)
})
// END card animation --------------//

//--------------------------------//
// Quote 
//--------------------------------//
document.querySelectorAll('.ACF-quote').forEach(container => {
  const tl = gsap.timeline({
    scrollTrigger: {
      trigger: container, // What element triggers the scroll
      scrub: false, // Add a small delay of scrolling and animation. `true` is direct
      start: "top bottom-=38%", // Can be top, center, bottom 
      end: "100% center", // Can be top, center, bottom 
    }
  })
  tl.from(container.querySelector('.inner'), {
    duration: 0.3,
    xPercent: 100,
    opacity: 0
  })
  tl.from(container.querySelectorAll('blockquote'), {
    duration: 0.3,
    opacity: 0
  })
  tl.from(container.querySelectorAll('cite'), {
    duration: 0.3,
    x: -50,
    opacity: 0
  })
  tl.from(container.querySelectorAll('hr'), {
    duration: 0.1,
    rotate: 0,
  }, '<')
})
// END Quote --------------//

